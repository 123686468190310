<template>
    <div class="main">
        <Title >公司简介</Title>
        <Pgp class="col8 wow slideInLeft" style="margin:0 auto">堃晟是历思旗下司法鉴定所的依托实验室，其基于严格的质量管理体系建立了以检测技术为核心的突出能力。现已通过环境、食品、毒品、微量、珠宝玉石及贵金属领域的多项CMA认证；并列入《福建省污染地块调查评估、治理修复单位专业机构推荐名录》，覆盖调查评估类、方案制定类和效果评估类3个类别。目前在福州、泉州、重庆、湖南等地设有子公司。
</Pgp>
        <ImagePreview class="img_s wow slideInRight" style="margin-bottom:2rem" :src="src1" :srcList="[src1]"></ImagePreview>
        <ImagePreview class="img  wow slideInLeft" :src="src2" :srcList="[src2]"></ImagePreview>
        <Title >检测实力</Title>
        <ImagePreview class="img wow slideInRight" :src="src3" :srcList="[src3]"></ImagePreview>
        <div class="img_box  wow slideInLeft">
            <ImagePreview class="s_img" :src="src4" :srcList="[src4]"></ImagePreview>
            <ImagePreview class="s_img" :src="src5" :srcList="[src5]"></ImagePreview>
        </div>
        <div class="b_bg wow slideInRight">
            实验室全面配置了Waters、Thermo Fisher、AB公司、foster + freeman、基恩士、奥林巴斯等生产的最新型进口液相、液质联用、ICP-MS、离子色谱、基因测序仪、超快速拉曼成像光谱仪、声像工作站、超级文检工作站、超景深三维立体显微系统、X射线荧光光谱仪、纳克级快速精准质谱仪等高端检验设备，走在了行业前沿。
        </div>
        <Title>历思毒品检验联合实验室</Title>
        <div class="col8 col6-4" style="display:flex;flex-wrap:wrap;margin:0 auto">
            <div class="col6" style="padding: 10px;
    box-sizing: border-box;
    text-align: left;
    text-indent: 2rem;
    letter-spacing: 2px;
    line-height: 1.7rem;
    margin-bottom: 1rem;">
                <p class=" wow slideInLeft">
                    联合实验室（厦门、福州、泉州、重庆）配备30余人资深毒品鉴定团队和行业顶级毒品检验设备，并通过了公安部禁毒情报技术中心组织的毛发、污水实验室能力验证，可为全国毒品检验鉴定提供优质服务。

                </p>
            </div>
            <div class="col4 wow slideInRight">
                <ImagePreview class="s_img" :src="src6" :srcList="[src6]"></ImagePreview>
            </div>
        </div>
        
        
    </div>
</template>
<script>
import src1 from '../../assets/Domain/KS/1.png';
import src2 from '../../assets/Domain/KS/2.png';
import src3 from '../../assets/Domain/KS/3.png';
import src6 from '../../assets/Domain/KS/4.png';

import src4 from '../../assets/Domain/KS/4.jpg';
import src5 from '../../assets/Domain/KS/3.jpg';
export default {
    data(){
        return {
            src1:src1,
            src2:src2,
            src3:src3,
            src4:src4,
            src5:src5,
            src6:src6,
        }
    },
}
</script>
<style scoped>
.col5{
    width:50%;
}
.col8{
    width:80%;
}
.col6-4 .col6{
    width:60%;
}
.col6-4 .col4{
    width:40%
}
@media screen and (max-width:900px){
    .col5{
        width:100%;
    }
    .col8{
        width:100%
    }
}
@media screen and (max-width:768px){
    .col6-4 .col6{
        width:100%;
    }
    .col6-4 .col4{
        width:100%
    }
    
}
.main{
    width:80vw;
    margin:0 auto;
    max-width:1600px;
    margin-bottom:2rem;
}
.img_s{
    min-width:300px;
    width:25vw;
    max-width:500px;
    margin:0 auto;
}
.img{
    min-width:300px;
    width:40vw;
    max-width:800px;
    margin:0 auto;
    background: #fff;
    padding: 2rem;
    padding-bottom: 0;
}
.img_box{
    display: flex;
    width: 40vw;
    margin: 0 auto;
    padding: 0 2rem 2px 2rem;
    background: #fff;
    max-width: 800px;
    flex-wrap: wrap;
    min-width:300px;
}
.b_bg{
    display: flex;
    width: 40vw;
    margin: 0 auto;
    padding: 3rem 2rem;
    color: #fff;
    background: #00b0f0;
    /* padding: 2rem; */
    text-align:left;
    line-height:1.5rem;
    font-size:1rem;
    min-width: 300px;
    max-width: 800px;
}
.s_img{
    min-width: 222px;
    width: 45%;
    max-width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 4px;
}
.icon{
    width:30px;
    height:30px;
    margin-right:10px;
}
.title-box{
    margin:1rem 0;
    font-size:1.3rem;
    color:#027DB4;
    display:flex;
    text-align:left;
    align-items:center;
    font-weight:bold;
}
.box{
    margin:3rem 0;
}
</style>